<script>
import GlobalOptionForm from "@/views/pages/settings/general/forms/global-option-form.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import AddButton from "@/components/buttons/add-button.vue";

/**
 * Customers Component
 */
export default {
  components: {
    AddButton,
    GlobalOptionForm,
    CustomTable
  },
  data() {
    return {
      formModalId:'global-option-form-modal',
      showModalId:'global-option-show-modal',
      table:{
        url:'/global-options',
        params: {
        },
        fields:[
          { key: "id", label: "Cod", sortable: true },
          { key: "name", label: "Nombre", sortable: true },
          { key: "key", label: "Slug", sortable: false },
          { key: "description", label: "Descripción", sortable: true },
          { key: "value", label: "Valor", sortable: false },
        ],
        formatted_fields:[
          'value',
          'description'
        ]
      },
      formSelectedItemId:null,   
      showSelectedItemId:null,
      clone:false
    };
  },
  methods: {
    showEditModal(item, clone = false) {
      this.formSelectedItemId = item?.id || null;
      this.clone = clone;
      this.$bvModal.show(this.formModalId);
    },
    showViewModal(item) {
      this.showSelectedItemId = item?.id || null;
      this.$bvModal.show(this.showModalId);
    },
    onSuccess(){
      this.$refs.globalOptionTable.refresh();
    },
  }
};
</script>

<template>
    <div class="card h-100">
          <div class="card-body">
            <GlobalOptionForm :modal-id="formModalId" :id="formSelectedItemId" @onSuccess="onSuccess" :clone="clone"></GlobalOptionForm>
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="card-title">
                    Parámetros globales
                </h4>
                <AddButton @click="showEditModal"></AddButton>
            </div>
            <CustomTable
              :url="table.url"
              :fields="table.fields"
              :formatted-fields="table.formatted_fields"
              :params="table.params"
              @edit="showEditModal"
              ref="globalOptionTable"
              :show-view="false"
              :showSoftDelete="false"
              @show="showViewModal"
              :show-modal-id="showModalId"
            >
              <template #cell-value="{ item }">
                {{ item?.value | truncateText(30) }}
              </template>
              <template #cell-description="{ item }">
                <span v-b-tooltip.hover :title="item?.description">{{ item?.description | truncateText(30) }}</span>
              </template>
            </CustomTable>
          </div>
        </div>
</template>