<script>
import api from "@/helpers/apirest/api";
import InfoTooltip from "@/components/widgets/info-tooltip.vue";
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";

export default {
    props: {
    },
    data() {
        return {
            cardApplicationTypes: [],
            loading: false,
            params:{
            }
        }
    },
    components: {
        SelectAutocompleteField,
        InfoTooltip
    },
    methods: {
        async loadData() {
            try {
                this.loading = true;            
                const response = await api.get({
                    url:'card-application-types',
                    params:{
                        
                    }
                });
                this.cardApplicationTypes = response?.data?.data;
            } catch (error) {
                this.cardApplicationTypes = [];
            }

            this.loading = false; 
        },
        async productChange(product, cardApplicationType) {
            try{
                await api.save({
                    url: 'card-application-types',
                    id: cardApplicationType?.id,
                    data: {
                        ...cardApplicationType,
                        product_id: product?.id
                    },
                    config: {
                        withLoading: false
                    }
                });
            } catch (error) {
                this.cardApplicationTypes = [];
            }
        }
    },
    mounted() {
        this.loadData();
    },
};
</script>

<template>
    <div class="card h-100">
        <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="card-title">
                    Tipos de solicitudes
                </h4>
            </div>
            <div>
                <p class="text-muted mb-4">Asigna el producto para el pago de la solicitud, deja en blanco en caso de no generar costo.</p>
                <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                        <b-skeleton-table
                            :rows="6"
                            :columns="2"
                            :hide-header="false"
                            :table-props="{ bordered: true, striped: true }"
                        ></b-skeleton-table> 
                    </template>
                    <table class="table table-hover table-sm mb-0 table-centered table-nowrap">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Producto <InfoTooltip title="Indica el producto que se utilizará para generar el pago de la solicitud." /></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="cardApplicationType in cardApplicationTypes" :key="cardApplicationType.id">
                                <th>{{ cardApplicationType.name }}</th>
                                <td>
                                    <SelectAutocompleteField
                                        :url="`/products`"
                                        :params="params"
                                        :placeholder="'Seleccione el producto'"
                                        v-model="cardApplicationType.product"
                                        :on-change-payload="cardApplicationType"
                                        @change="productChange"
                                    ></SelectAutocompleteField>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-skeleton-wrapper>
            </div>
        </div>
    </div>
</template>