<script>
import { required } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';

const initialState = () => {
    return {
        submitted: false,
        global_option: {
            name: '',
            description: '',
            key: '',
            value: '',
        }
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
    },
    validations: {
        global_option: {
            name: { required },
            description: {  },
            key: { required },
            value: {  },
        }
    },
    methods: {
        async shown() {
            if (this.id) {
                const response = await api.get({
                    url: `global-options/${this.id}`,
                    config: {
                        withLoading: true
                    }
                });
                this.global_option = response?.data?.data;
            }
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = this.global_option;
            await api.save({
                url: 'global-options',
                id: this.global_option?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess");
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
        showUploadModal() {
            this.$bvModal.show(this.uploadModalId);
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de parámetro global'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'md'">
        <div class="row">

            <div class="col-lg-12">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">General</h4>
                        <p class="card-title-desc">
                            Ingresa los datos generales de la categoría
                        </p>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="name">Nombre</label>
                                <input id="name" v-model="global_option.name" type="text" class="form-control" placeholder="Ingresa un nombre"
                                    :class="{ 'is-invalid': $v.global_option.name.$error && submitted }" />
                                <div v-if="!$v.global_option.name.required && submitted" class="invalid-feedback">Nombre es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="key">Slug</label>
                                <input id="key" v-model="global_option.key" type="text" class="form-control" placeholder="Ingresa un slug"
                                    :class="{ 'is-invalid': $v.global_option.key.$error && submitted }" />
                                <div v-if="!$v.global_option.key.required && submitted" class="invalid-feedback">Slug es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="value">Valor</label>
                                <input id="value" v-model="global_option.value" type="text" class="form-control" placeholder="Ingresa un valor"
                                :class="{ 'is-invalid': $v.global_option.value.$error && submitted }" />
                                <div v-if="$v.global_option.value.$error && submitted" class="invalid-feedback">Valor inválido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="description">Descripción</label>
                                <input id="description" v-model="global_option.description" type="text" class="form-control" placeholder="Ingresa una descripción"
                                :class="{ 'is-invalid': $v.global_option.description.$error && submitted }" />
                                <div v-if="$v.global_option.description.$error && submitted" class="invalid-feedback">Descripción inválido</div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>