<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import CardApplicationTypePartial from "./partials/card-application-type-partial.vue";
import GlobalOptionsPartial from "./partials/global-options-partial.vue";

/**
 * Stores Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    CardApplicationTypePartial,
    GlobalOptionsPartial
  },
  data() {
    return {
      pageHeader:{
        title: "Ajustes Generales",
        breadcrumbs: [
          {
            text: "Ajustes Generales",
            href: "/settings/general",
            active: true
          }
        ],
      },
    }
    
  },
  methods: {
  },
  mounted() {
  },
  beforeDestroy() {
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <!-- <TemplateForm :modal-id="modalId" :id="selectedItemId" @onSuccess="onSuccess"></TemplateForm>     -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body d-flex justify-content-between">
            <p class="text-muted font-13">Gestiona la configuración general de la plataforma</p>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-6">
        <CardApplicationTypePartial></CardApplicationTypePartial>
      </div>
      <div class="col-xl-8 col-sm-6">
        <GlobalOptionsPartial></GlobalOptionsPartial>
      </div>
    </div>
  </Layout>
</template>